<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <div class="tabs">
            <a href="/download/logo.svg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Logo</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Grafika1.jpg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Grafika promocyjna 1</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Grafika2.jpg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Grafika promocyjna 2</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Grafika3.jpg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Grafika promocyjna 3</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Paradiso_plakat_A3.pdf" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Plakat A3</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Paradiso_plakat_A4.pdf" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Plakat A4</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/Paradiso_ulotkaA6.pdf" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Ulotka A6</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/zdjecie_w_tle.jpg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Zdjęcie w tle</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/download/2022/zdjecie_wydarzenia.jpg" download class="tab">
              <label class="tab-label" for="rd1">
                <p>Zdjęcie wydarzenia</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
        
          

            <!-- <a href="/pdf/Ogolne-warunki-uczestnictwa.pdf" download class="tab">
              <label class="tab-label" for="rd3">
                <p>Ogólne warunki uczestnictwa</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a>
            <a href="/pdf/regulamin.pdf" download class="tab">
              <label class="tab-label" for="rd3">
                <p>Regulamin wydarzenia</p>
                <p class="tab-download">Pobierz</p>
              </label>
            </a> -->
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
/* Accordion styles */
$midnight: #2c3e50;
$clouds: #ecf0f1;
.tabs {
  // border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    /* background: #2c3e50; */
    font-weight: bold;
    cursor: pointer;
    color: #000;
    border-bottom: 1px solid $red;
    p {
      transition: all 0.35s;
    }
    /* Icon */
    &:hover {
      p {
        &:first-child {
          transform: translateX(8px);
        }
      }
      // background: darken($midnight, 10%);
    }
    // &::after {
    //   content: "\276F";
    //   width: 1em;
    //   height: 1em;
    //   text-align: center;
    //   transition: all 0.35s;
    // }
  }
  &-download {
    font-weight: 400;
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $midnight;
    background: white;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    // background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}
</style>
